import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import MainLayout from './layout/MainLayout';
import Business from './pages/about/Business';
import Contact from './pages/contact/Contact';
import Home from './pages/home/Home';
import NotFoundPage from './pages/notfound/NotFoundPage';
import ProductCar from './pages/product/ProductCar';
import Privacy from './pages/terms/Privacy';
import Terms from './pages/terms/Terms';
import { AccentHistory, AudiA4Info, AudiQ5Info, CamryOverview, SantaFeHistory, SonataHistory, VolkswagenPassatInfo } from './pages/blog/VHR/vin/car-blog-vin';
import ProductList from './pages/productlist/ProductList';


export default function App({initialData}) {
 
  return (
    <div className="App font-[Roboto] font-extralight">
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/about' element={<Business />} />
          <Route path='/dcv/terms' element={<Terms />} />
          <Route path='/dcv/privacy' element={<Privacy />} />
{/* 
          <Route path="/blog/vehicle-history-reports/car-reviews/audi-a4/" element={<AudiA4Info />} />
          <Route path="/blog/vehicle-history-reports/car-reviews/audi-q5/" element={<AudiQ5Info />} />
          <Route path="/blog/vehicle-history-reports/car-reviews/volkswagen-passat" element={<VolkswagenPassatInfo />} />
          <Route path="/blog/vehicle-history-reports/car-reviews/toyota-camry/" element={<CamryOverview/>} />
          <Route path="/blog/vehicle-history-reports-analysis/vhr/vehicle-history-records-improve-car-ownership-experience/" element={<History />} />
          <Route path="/blog/vehicle-history-reports/car-reviews/hyundai-sonata/" element={<SonataHistory />} />
          <Route path="/blog/vehicle-history-reports/car-reviews/hyundai-accent/" element={<AccentHistory/>} />
          <Route path="/blog/vehicle-history-reports/car-reviews/hyundai-santa-fe/" element={<SantaFeHistory/>} /> */}
           
          {/* <Route path='/check/history/:lot/:tag' element={<Navigate to={`/en/auto/${lot}/${tag}`} replace/>} /> */}

          <Route path='/:make/:vin' element={<ProductCar  data={initialData}/>} />
          <Route path='/:make' element={<ProductList  data={initialData}/>} />
           <Route path='*' element={<NotFoundPage/>}/> 
        </Route>
      </Routes>
    </div>
  );
}


