import { Button, Option, Select } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import dcvback from "../../assets/dcvback.jpg";
import CustomCarousel from "../../components/customs/slider";
import AnimatedSkeleton from "../../components/skeleton/SuggestSkeleton";
import { publicRequest } from "../../utils/requestMethods";
import { _getMakes, _getNewestProduct, _getNewProduct, _getoldProduct, _getPopularProduct, _getRecentProduct } from "../../utils/requestUtils";







const Home = ({ initialData }) => {
  const PA = process.env.REACT_APP_ASSETS_BASE_URL_WITHOUT_SLASH;
  const [selectedMake, setSelectedMake] = useState('');
  const [makes, setMakes] = useState([]);

  const [newCarData, setNewCarData] = useState(initialData?.data);
  // const [recentCarData, setRecentCarData] = useState(initialData?.recent);
  // const [popularCarData, setPopularCarData] = useState(initialData?.pupular);
  const [newestCarData, setNewestData] = useState(initialData?.newest);
  const [oldCarData, setOldData] = useState(initialData?.old);
  const [loading, setLoading] = useState(true);
  const [vinCode, setVinCode] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const validateVin = (vin) => {
    if (vin.length < 17) {
      return "VIN must be exactly 17 characters long.";
    }
    if (vin.length > 17) {
      return "VIN cannot exceed 17 characters.";
    }
    return "";
  };

  const handleSearch = async () => {
    const validationError = validateVin(vinCode?.trim());
    if (validationError) {
      setError(validationError);
      return;
    }
    // Proceed with search or other logic
    setError("");
    try {

      const response = await publicRequest.get(`/public/get/dcv/${vinCode}`);
      const data = response?.data;
      if (data?.vin) {
        navigate(`/${data?.makes?.toLowerCase()}/${vinCode}`);
        window.location.reload();
      }
    } catch (err) {
      setError("Product not found for this VIN");
    }
  };

  // Fetch new products on component mount
  useEffect(() => {
    try {
      _getNewProduct().then((data) => {
        setNewCarData(data);
        setLoading(false);
      });

      // _getRecentProduct().then((data) => {
      //   setRecentCarData(data);
      //   setLoading(false);
      // });
      // _getPopularProduct().then((data) => {
      //   setPopularCarData(data);
      //   setLoading(false);
      // });
      _getNewestProduct().then((data) => {
        setNewestData(data);
        setLoading(false);
      });
      _getoldProduct().then((data) => {
        setOldData(data);
        setLoading(false);
      });
      _getMakes().then((data) => {
        setMakes(data);
      })
    } catch (err) {
      console.error(err);
    }
  }, []);


  const handleMakeChange = (e) => {
    setSelectedMake(e);
  };



  return (
    <div className="overflow-hidden text-white bg-gray-900">

      <Helmet>
        <title>D.C.V - Damaged Car Vin | Car Information from USA</title>
        <meta
          name="description"
          content="VIN Lookup, Auction Results, and Vehicle Details"
        />

        <link rel="canonical" href="https://damagedcarvin.com/" />
      </Helmet>

      <div className="relative w-full h-[900px] md:h-screen bg-cover bg-center bg-fixed" style={{ backgroundImage: `url(${dcvback})` }}>
        <div className="absolute inset-0 bg-black bg-opacity-70  flex flex-col items-center justify-center p-8 sm:p-12 md:p-16 lg:p-24">
          {/* Heading and Description */}

          <div className="text-center mb-12 px-4">
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-yellow-400 mb-4">Search Cars by Make</h1>
            <p className="text-white mb-6">Select a car make from the dropdown to view available models.</p>
            <form className="new-search-psa  flex flex-col gap-y-5 2xl:gap-y-10 " action="&">
              {/* Car Make Selection */}
              <div className="w-full max-w-md mx-auto">
                <Select
                  name="make"
                  id='make'
                  color="yellow"
                  label="Select Car Make"
                  className="text-white"

                  onChange={(e) => handleMakeChange(e)}
                >
                  {makes?.map((make) => (
                    <Option key={make.id} value={make.make}>
                      {make.make}
                    </Option>
                  ))}
                  {/* Add more car makes as needed */}
                </Select>
                <Button
                  disabled={!selectedMake}
                  onClick={() => {
                    navigate(`/${selectedMake?.toLowerCase()}?page=1`);
                    window.location.reload();
                  }}
                  color="light-blue"
                  className="mt-4 px-8 py-3 rounded-full text-white bg-blue-500 hover:bg-blue-600 transition"
                >
                  Search Cars
                </Button>

              </div>
            </form>
          </div>
          {/* Heading and Description */}
          <div className="text-center mb-12 px-4 sm:px-8 md:px-12">
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-yellow-400 mb-4 leading-tight">
              Every Vehicle Information: VIN codes, Damages, Images, VHR
            </h1>
            <p className="text-sm sm:text-base md:text-lg lg:text-xl text-white mb-6">
              DamagedCarVin provides essential details about used vehicles, including VIN codes, damage reports, and vehicle history.
            </p>
            <ul className="text-sm sm:text-base md:text-lg text-white list-disc mb-8 mx-auto max-w-lg">
              <li>Extensive Accident Histories</li>
              <li>Detailed Damage Reports with Images</li>
              <li>VIN Codes and Lot Information</li>
            </ul>
          </div>

          {/* VIN Search Input */}
          <div className="flex flex-col items-center w-full max-w-lg mx-4 sm:mx-8 md:mx-12">
            <div className="relative w-full">
              <input
                type="text"
                value={vinCode}
                onChange={(e) => setVinCode(e.target.value?.trim())}
                placeholder="Enter VIN Code (17 characters)"
                maxLength={17}
                className="w-full p-4 rounded-lg border border-gray-400 bg-gray-800 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-300 transition duration-300 ease-in-out"
              />
              <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
                <svg className="w-6 h-6 text-yellow-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 100-6 3 3 0 000 6zm-1 0a4 4 0 11-8 0 4 4 0 018 0zM5 21h14a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z" />
                </svg>
              </div>
            </div>
            {error && <p className="text-red-400 mt-2 text-sm">{error}</p>}
            <Button onClick={handleSearch} color="light-blue" className="mt-4 px-8 py-3 rounded-full text-white bg-blue-500 hover:bg-blue-600 transition duration-300 ease-in-out">
              Search VIN
            </Button>
          </div>
        </div>
      </div>
      {/* Most Popular Section */}
      <div className="bg-gray-800 py-16 px-4">
        <div className="max-w-6xl mx-auto">
          <span className="text-xl font-semibold text-yellow-500 ">Last Added Vehicles</span>
          <div className="w-full">
            {/* Display Skeletons if Loading */}
            {(loading && newCarData?.length === 0) && (
              <div className="flex  gap-3">
                {Array.from({ length: 4 }).map((_, index) => (
                  <AnimatedSkeleton key={index} />
                ))}
              </div>
            )}

            {/* Display Carousel if Data is Loaded */}
            {(!loading && newCarData?.length !== 0) && (
              <CustomCarousel data={newCarData} />
            )}
          </div>
        </div>
      </div>



      {/* Most Popular Section */}
      <div className="bg-gray-800 py-16 px-4">
        <div className="max-w-6xl mx-auto  ">
          <span className="text-xl font-semibold text-yellow-500 ">Newest Vehicles</span>
          <div className="w-full">
            {/* Display Skeletons if Loading */}
            {(loading && newestCarData?.length === 0) && (
              <div className="flex  gap-3">
                {Array.from({ length: 4 }).map((_, index) => (
                  <AnimatedSkeleton key={index} />
                ))}
              </div>
            )}

            {/* Display Carousel if Data is Loaded */}
            {(!loading && newestCarData?.length !== 0) && (
              <CustomCarousel data={newestCarData} />
            )}
          </div>
        </div>
      </div>

      {/* Information Section */}
      <div className="bg-gray-900 text-white py-16">
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="p-6 bg-gray-800 rounded-lg shadow-lg">
              <span className="text-xl font-semibold mb-4">Detailed Records</span>
              <p className="text-gray-300">Access extensive histories of accidents, maintenance, repairs, and even lot prices.</p>
            </div>
            <div className="p-6 bg-gray-800 rounded-lg shadow-lg">
              <span className="text-xl font-semibold mb-4">Damage Reports</span>
              <p className="text-gray-300">View images showing specific damages to various parts of the car.</p>
            </div>
            <div className="p-6 bg-gray-800 rounded-lg shadow-lg">
              <span className="text-xl font-semibold mb-4">Vehicle Chronicles</span>
              <p className="text-gray-300">Get a complete overview to aid your decision-making process.</p>
            </div>
            <div className="p-6 bg-gray-800 rounded-lg shadow-lg">
              <span className="text-xl font-semibold mb-4">Car Comparison</span>
              <p className="text-gray-300">Compare different cars, understand their histories, and determine fair pricing based on detailed information about specific models or VIN codes.</p>
            </div>
          </div>
        </div>
      </div>



     

      {/* Most Popular Section */}
      <div className="bg-gray-800 py-16 px-4">
        <div className="max-w-6xl mx-auto">
          <span className="text-xl font-semibold text-yellow-500">2013-2015  Vehicles</span>
          <div className="w-full">
            {/* Display Skeletons if Loading */}
            {(loading && oldCarData?.length === 0) && (
              <div className="flex  gap-3">
                {Array.from({ length: 4 }).map((_, index) => (
                  <AnimatedSkeleton key={index} />
                ))}
              </div>
            )}

            {/* Display Carousel if Data is Loaded */}
            {(!loading && oldCarData?.length !== 0) && (
              <CustomCarousel data={oldCarData} />
            )}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-16 px-4">
        <div className="max-w-4xl mx-auto text-center">
          <span className="text-3xl md:text-4xl font-bold mb-4">Make Informed Decisions</span>
          <p className="text-lg md:text-xl mb-8">  For car buyers, DamagedCarVin is an invaluable tool.
            It lets you compare different cars, understand their histories, and determine fair pricing based on detailed information about specific models or VIN codes.
            This ensures you make an informed decision, whether you're buying or negotiating a price.
            Be alert for red flags such as inconsistent mileage, frequent repairs, or severe accident history.
            These indicators can signal serious underlying problems that might lead to costly fixes or safety risks.
            Always scrutinize the vehicle history report to cover your investment.
            DamagedCarVin isn’t just about data; it's about making confident, informed choices.
            Equipped with comprehensive knowledge about a car's past, you can negotiate better deals or avoid problematic vehicles entirely, ensuring a safer and smarter purchase.</p>
          <Button color="amber" >Get Started</Button>
        </div>
      </div>

    </div >
  );
};

export default Home;



// <div className="bg-gray-800 py-16 px-4">
// <div className="max-w-6xl mx-auto">
//   <span className="text-xl font-semibold text-yellow-500 ">Popular Make Vehicles</span>
//   <div className="w-full">
//     {/* Display Skeletons if Loading */}
//     {(loading && popularCarData?.length === 0) && (
//       <div className="flex  gap-3">
//         {Array.from({ length: 4 }).map((_, index) => (
//           <AnimatedSkeleton key={index} />
//         ))}
//       </div>
//     )}

//     {/* Display Carousel if Data is Loaded */}
//     {(!loading && popularCarData?.length !== 0) && (
//       <CustomCarousel data={popularCarData} />
//     )}
//   </div>
// </div>
// </div> 


 
      // <div className="bg-gray-800 py-16 px-4">
      //   <div className="max-w-6xl mx-auto">
      //     <span className="text-xl font-semibold text-yellow-500 "> Expensive Vehicles</span>
      //     <div className="w-full">
      //       {/* Display Skeletons if Loading */}
      //       {(loading && recentCarData?.length === 0) && (
      //         <div className="flex  gap-3">
      //           {Array.from({ length: 4 }).map((_, index) => (
      //             <AnimatedSkeleton key={index} />
      //           ))}
      //         </div>
      //       )}

      //       {/* Display Carousel if Data is Loaded */}
      //       {(!loading && recentCarData?.length !== 0) && (
      //         <CustomCarousel data={recentCarData} />
      //       )}
      //     </div>
      //   </div>
      // </div> 