import axios from 'axios';
 
const BASEURL = `${process.env.REACT_APP_BASE_URL}/api`;
const MAKEMODELURL = `https://auctioncar.onrender.com/en`;
export const publicRequest =  axios.create({
    baseURL:BASEURL
})


export const  makeModelRequest = axios.create({
    baseURL: MAKEMODELURL
})

